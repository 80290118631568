<template>
  <div class="form-container bg-w-silver px-5 pb-15">
    <div class="text-title font-weight-bold --prm text-center pt-7 pb-5">
      {{ $t("memberInfo.changeCreditCardInfo") }}
    </div>
    <div class="mb-3">
      <div class="text-medium --prm text-center py-2">
        現在ご登録中のカード
      </div>
      <v-container>
        <v-row>
          <v-col class="text-right" cols="5">{{ $t("common.cardName") }}：</v-col>
          <v-col cols="7">{{ cardInfo.holderName }}</v-col>
        </v-row>
        <v-row>
          <v-col class="text-right" cols="5">{{ $t("common.cardNumber") }}：</v-col>
          <v-col cols="7">{{ cardInfo.cardNo }}</v-col>
        </v-row>
        <v-row>
          <v-col class="text-right" cols="5">{{ $t("menu.main.items.expirationDate") }}：</v-col>
          <v-col cols="7">{{ cardExpireToDate(cardInfo.expire) }}</v-col>
        </v-row>
      </v-container>
    </div>
    <div class="form-container px-5 pb-15">
      <div class="text-medium --prm text-center py-2">
        新しいクレジットカード情報
      </div>
      <card-form new-card />
    </div>
  </div>
</template>

<script>
import CardForm from './CardForm.vue'
import { cardExpireToDate } from '@/utils/get-date'

export default {
  name: 'NewCardRegister',
  components: {
    CardForm
  },
  computed: {
    cardInfo () {
      return this.$store.state.client.cardInfo ?? {}
    },
    cardExpireToDate () {
      return cardExpireToDate
    }
  },
  async mounted () {
    await this.$doLoading(() => this.$store.dispatch('checkSavedCard'))
  }
}
</script>
